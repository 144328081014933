<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="申请公告日：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="专利类型：">
        <dic-radio-button
          :code.sync="queryInfo.patentType"
          type-code="036"
          query-name="patentType"
          name="专利类型"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="专利状态：">
        <dic-radio-button
          :code.sync="queryInfo.patentState"
          type-code="037"
          query-name="patentState"
          name="专利状态"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >
          <popover @popoverClick="popoverClick" />
          <el-button @click="multiDelete" style="margin-right: 0">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <el-button @click="importDataTrue = true">
            批量导入
            <i class="el-icon-upload2" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入专利名称"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="专利名称"
          align="center"
          width="230"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.patentName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="patentType"
          label="专利类型"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="patentState"
          label="专利状态"
          align="center"
          width="120"
        >
        </el-table-column>
        <!-- 发布日期未确定 -->
        <el-table-column
          prop="announcementPublicDate"
          label="申请公告日"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="announcementPerson"
          label="申请人"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agency"
          label="代理机构"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(row)"
            />
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="请输入企业名称"
      :visible.sync="importDataTrue"
      width="30%"
      @close="handleClose"
    >
      <el-input v-model="keyWord"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importDataTrue = false">取 消</el-button>
        <el-button type="primary" @click="importData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";
import ImportData from "@/components/ImportData.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "../Product/com/dataTime.vue";
import { pagination } from "@/mixins/pagination";
import axios from "axios";
import {
  getCompanyList,
  deleteById,
  findByPropertyFileKey,
  getPatentInformation,
} from "@/api/equity";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { updateProjectData } from "@/api/numberProject";
import { exportData } from "../../utils/tool";
import { URL } from "../../../config";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicRadioButton,
    ButtonTable,
    DataTime,
    Popover,
    DicCheckboxGroup,
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  data() {
    return {
      keyWord: "",
      importDataTrue: false,
      // 查询
      queryInfo: {
        columnName: "update_time",
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 搜索查询条件
        years: null, // 年
        month: null, // 月
        day: null, // 日
        order: 1, // 0正序 1倒叙
        id: [],
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: "专利类型",
          tagName: "patentType",
        },
        {
          name: "专利状态",
          tagName: "patentState",
          valLength: 0,
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    };
  },
  created() {
    this.search();
  },

  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      const res = await getCompanyList(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.total = res.data.total;
        this.projectTable.forEach((el) => {
          if (!el.dataIntegrity) {
            el.dataIntegrity = 0;
          }
        });
      }

      this.total = res.data.total;
    },
    searchCountry(val) {
      this.queryInfo.country = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.province = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.city = val;
        this.search();
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.projectFieldType =
          this.editTableObj.projectFieldType.concat(
            this.editTableObj.fieldChildrenId
          );
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.projectIndustryType =
          this.editTableObj.projectIndustryType.concat(
            this.editTableObj.industryChildrenId
          );
      }

      this.editTableObj.projectIndustryType = [
        ...new Set(this.editTableObj.projectIndustryType),
      ];
      this.editTableObj.projectFieldType = [
        ...new Set(this.editTableObj.projectFieldType),
      ];

      const res = await updateProjectData(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;

      this.search();
    },
    // 弹窗关闭
    handleClose() {
      this.importData = false;

      this.keyWord = "";
    },
    updateCode(val, tag) {
      // this.nextTick(() => {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
      // })
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
      }
      if (
        Number(this.queryInfo.month) < 10 &&
        Number(this.queryInfo.month) > 0
      ) {
        this.queryInfo.month = "0" + this.queryInfo.month;
      }
      if (Number(this.queryInfo.day) < 10 && Number(this.queryInfo.day) > 0) {
        this.queryInfo.day = "0" + this.queryInfo.day;
      }
      this.search();
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });
      this.queryInfo.pageNum = 1;
      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "industry":
          this.queryInfo.industryName = "";
          this.queryInfo.industry = [];
          break;
        case "technologyType":
          this.queryInfo.technologyTypeName = "";
          this.queryInfo.technologyType = [];
          break;
        case "field":
          this.queryInfo.fieldName = "";
          this.queryInfo.field = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    editItem(row) {
      let text = this.$router.resolve({
        path: "/equity/EquityList/equityInfo",
        query: {
          personType: 1,
          id: row.id,
        },
      });
      window.open(text.href, "_blank");
    },

    addItem() {
      this.$router.push({
        name: "EquityInfo",
        query: {
          personType: 0,
        },
      });
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.id = val.map((item) => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteById(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
            this.delete.id = [];
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);
      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length > 0) {
        this._deleteProject(this.delete.multiId);
      } else {
        this.$message.warning("请选择至少一条数据");
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { id: [], size: "" });
      } else if (val == 0 && this.queryInfo.id.length) {
        Object.assign(this.queryInfo, {
          idList: this.queryInfo.id,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/property/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "专利数据.xls"
      );
    },
    //下载产品附件
    async downloadClick(row) {
      const res = await findByPropertyFileKey({ id: row.id });
      if (res.code == 200) {
        const paramsurl = res.data.fileName;
        // const URL = 'http://192.168.0.6:8085/'
        axios({
          method: "GET",
          url: `${URL}/public/downloadUrlFile`,
          params: { urlStr: paramsurl },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization,
          },
        })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/msword",
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            console.log(href);
            a.download = row.fileName.substring(
              row.fileName.lastIndexOf("/") + 1
            ); //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch((err) => {
            //this.$message.error('文件下载失败')
          });
      }
    },
    async seeClick(row) {
      const res = await findByPropertyFileKey({ id: row.id });
      if (res.code == 200) {
        const paramsurl = res.data.fileKey;
        if (paramsurl) {
          this.fileUrl = row.reportKeys;
          window.open(paramsurl);
          // this.isViewPdf20 = true;
        } else {
          this.$alert("该报告暂无附件!");
        }
      }
    },
    async importData() {
      const res = await getPatentInformation({ keyWord: this.keyWord });
      if (res.code == 200) {
        this.$message.success("新增成功");
        this.importDataTrue = false;
        this.search();
      } else {
        this.$message.error("新增失败");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
